<template>
  <v-card flat>
    <v-card-title>Datos del cliente</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="formData.centerIds"
            :items="centers"
            :loading="loadingCenters"
            :rules="[$data.$globalRules.required]"
            item-value="const"
            item-text="title"
            label="Cliente - Centro/s"
            dense
            multiple
            @change="getContacts"
          >
            <template #selection="selection">
              <v-chip
                small
                close
                @click:close="deleteClient(selection.item.const)"
                color="grey lighten-3"
                class="my-1"
              >
                <filter-btn
                  route-name="centers"
                  :criteria="[{ field: 'id', operator: 'e', value: selection.item.const }]"
                  target-blank
                  icon="mdi-open-in-new"
                  :size="16"
                  class="mr-1"
                />
                <span>{{ selection.item.title }}</span>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="formData.contactIds"
            :items="contacts"
            :loading="loadingContacts"
            :rules="[$data.$globalRules.required]"
            :disabled="
              !formData.centerIds || (formData.centerIds && formData.centerIds.length === 0)
            "
            item-value="id"
            item-text="name"
            label="Responsable/s de la formación"
            dense
            multiple
          >
            <template #selection="selection">
              <v-chip
                small
                close
                @click:close="deleteContact(selection.item.id)"
                color="grey lighten-3"
                class="my-1"
              >
                <filter-btn
                  route-name="contacts"
                  :criteria="[{ field: 'id', operator: 'e', value: selection.item.id }]"
                  target-blank
                  icon="mdi-open-in-new"
                  :size="16"
                  class="mr-1"
                />
                <span>{{ selection.item.name }}</span>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field v-model="formData.interlocutor" label="Interlocutor" dense> </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    filterBtn: () => import("@/components/crud-md/CrudMdFilterItemBtn"),
  },
  props: {
    formData: { type: Object, required: true },
  },
  data: () => ({
    centers: [],
    loadingCenters: null,
    contacts: [],
    loadingContacts: null,
    startDateActivePicker: null,
    startDateMenu: null,
    currentService: null,
  }),
  async mounted() {
    this.currentService = this.$store.state.currentService;
    await this.getCenters();
    await this.getContacts();
  },
  methods: {
    async getCenters() {
      try {
        this.loadingCenters = true;
        const response = await this.currentService.getCenters();
        this.centers = response.data;
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar los centros disponibles | " + e);
      } finally {
        this.loadingCenters = false;
      }
    },
    async getContacts() {
      if (this.formData.centerIds?.length) {
        try {
          this.loadingContacts = true;
          const response = await this.currentService.getCentersContacts(this.formData.centerIds);
          this.contacts = response.data;
        } catch (e) {
          this.$notifyError(undefined, "Error al descargar los centros disponibles | " + e);
        } finally {
          this.loadingContacts = false;
        }
      }
    },
    deleteClient(centerId) {
      const centerIndex = this.formData.centerIds.findIndex(c => c.id === centerId);
      this.formData.centerIds.splice(centerIndex, 1);

      if (!this.formData.centerIds.length) this.formData.contactIds = [];
    },
    deleteContact(contactId) {
      const contactIndex = this.formData.contactIds.findIndex(c => c.id === contactId);
      this.formData.contactIds.splice(contactIndex, 1);
    },
  },
};
</script>

<style scoped></style>
